<template>
  <div class="container flex items-center justify-center">
    <template v-if="!userStore.isLoggedIn">
      <div
        class="flex flex-col md:flex-row relative columns mb-4 md:mb-10 mt-5 mx-0 shadow bg-white rounded-xl z-20 px-5 pt-8 pb-10 text-center"
      >
        <CommonLogin
          ref="login"
          v-model:email="input.email"
          v-model:password="input.password"
          :try-login="true"
        />
      </div>
    </template>
    <template v-else-if="userStore.isLoggedIn">
      <div class="grid gap-6 mb-16 text-center">
        <div
          class="flex flex-col md:flex-row relative columns mb-4 md:mb-10 mt-5 mx-0 shadow bg-white rounded-xl z-20 px-5 pt-8 pb-10 text-center"
        >
          <div class="px-5 label-m text-red">
            {{ $t('YouAreNowLoggedIn') }}
          </div>
        </div>
        <NuxtLink
          :to="{
            name: 'frontpage',
          }"
        >
          <CommonButton bg="bg-blue" text="text-white">
            {{ $t('login.GoToHomepage') }}
          </CommonButton>
        </NuxtLink>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'nuxt/app'

const userStore = useUserStore()
const { user } = storeToRefs(userStore)
const input = ref({ email: '', password: '' })

const route = useRoute()

if (userStore.isLoggedIn) {
  if (route.query && route.query.redirect) {
    navigateTo({ name: route.query.redirect as string })
  }
}

// THIS IS A HACY WAY TO DEAL WITH THE COMPLEX LOGIN COMPONENT INSTEAD OF USING EMITS
watch(user, () => {
  if (userStore.isLoggedIn) {
    if (route.query && route.query.redirect) {
      navigateTo({ name: route.query.redirect as string })
    }
  }
})
</script>
